// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-aws-consulting-partner-js": () => import("./../../../src/pages/aws-consulting-partner.js" /* webpackChunkName: "component---src-pages-aws-consulting-partner-js" */),
  "component---src-pages-aws-devops-js": () => import("./../../../src/pages/aws-devops.js" /* webpackChunkName: "component---src-pages-aws-devops-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-customer-success-book-js": () => import("./../../../src/pages/customer-success-book.js" /* webpackChunkName: "component---src-pages-customer-success-book-js" */),
  "component---src-pages-detailed-policies-[slug]-js": () => import("./../../../src/pages/detailedPolicies/[slug].js" /* webpackChunkName: "component---src-pages-detailed-policies-[slug]-js" */),
  "component---src-pages-empi-syt-js": () => import("./../../../src/pages/empiSYT.js" /* webpackChunkName: "component---src-pages-empi-syt-js" */),
  "component---src-pages-empower-foundation-js": () => import("./../../../src/pages/empower-foundation.js" /* webpackChunkName: "component---src-pages-empower-foundation-js" */),
  "component---src-pages-empower-js": () => import("./../../../src/pages/empower.js" /* webpackChunkName: "component---src-pages-empower-js" */),
  "component---src-pages-empower-pryzm-js": () => import("./../../../src/pages/empower-pryzm.js" /* webpackChunkName: "component---src-pages-empower-pryzm-js" */),
  "component---src-pages-empower-xchange-js": () => import("./../../../src/pages/empower-xchange.js" /* webpackChunkName: "component---src-pages-empower-xchange-js" */),
  "component---src-pages-gcp-consulting-partnersssssss-js": () => import("./../../../src/pages/gcp-consulting-partnersssssss.js" /* webpackChunkName: "component---src-pages-gcp-consulting-partnersssssss-js" */),
  "component---src-pages-gen-ai-js": () => import("./../../../src/pages/gen-ai.js" /* webpackChunkName: "component---src-pages-gen-ai-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-resources-blogs-[slug]-js": () => import("./../../../src/pages/resources/blogs/[slug].js" /* webpackChunkName: "component---src-pages-resources-blogs-[slug]-js" */),
  "component---src-pages-resources-blogs-js": () => import("./../../../src/pages/resources/blogs.js" /* webpackChunkName: "component---src-pages-resources-blogs-js" */),
  "component---src-pages-resources-case-studies-js": () => import("./../../../src/pages/resources/Case-Studies.js" /* webpackChunkName: "component---src-pages-resources-case-studies-js" */),
  "component---src-pages-resources-data-sheets-js": () => import("./../../../src/pages/resources/data-sheets.js" /* webpackChunkName: "component---src-pages-resources-data-sheets-js" */),
  "component---src-pages-resources-detailed-blog-[slug]-js": () => import("./../../../src/pages/resources/detailedBlog/[slug].js" /* webpackChunkName: "component---src-pages-resources-detailed-blog-[slug]-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-resources-latest-js": () => import("./../../../src/pages/resources/latest.js" /* webpackChunkName: "component---src-pages-resources-latest-js" */),
  "component---src-pages-resources-media-releases-js": () => import("./../../../src/pages/resources/media-releases.js" /* webpackChunkName: "component---src-pages-resources-media-releases-js" */),
  "component---src-pages-resources-videos-js": () => import("./../../../src/pages/resources/videos.js" /* webpackChunkName: "component---src-pages-resources-videos-js" */),
  "component---src-pages-resources-webinars-js": () => import("./../../../src/pages/resources/webinars.js" /* webpackChunkName: "component---src-pages-resources-webinars-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-success-page-js": () => import("./../../../src/pages/success-page.js" /* webpackChunkName: "component---src-pages-success-page-js" */),
  "component---src-pages-unique-about-lumiq-js": () => import("./../../../src/pages/unique-about-lumiq.js" /* webpackChunkName: "component---src-pages-unique-about-lumiq-js" */)
}

